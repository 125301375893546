import { useEffect, useState } from "react";

const usePWADetection = () => {
  const [isPWA, setIsPWA] = useState(false);

  useEffect(() => {
    const checkPWA = () => {
      // Check for iOS PWA or Android/Desktop PWA
      const isInStandaloneMode =
        window.navigator.standalone === true ||
        window.matchMedia("(display-mode: standalone)").matches;
      setIsPWA(isInStandaloneMode);
    };

    checkPWA();

    // Optional: Listen to changes in display mode (like switching from browser to PWA)
    const mediaQuery = window.matchMedia("(display-mode: standalone)");
    mediaQuery.addEventListener("change", checkPWA);

    // Cleanup the event listener on component unmount
    return () => {
      mediaQuery.removeEventListener("change", checkPWA);
    };
  }, []);

  return isPWA;
};

export default usePWADetection;
