import React, { useEffect, useState } from "react";
import "./Browse.css";
import {
  Col,
  Container,
  Modal,
  ModalHeader,
  Row,
  Spinner,
} from "react-bootstrap";
import { BsFillXOctagonFill, BsFunnelFill } from "react-icons/bs"; // Import the BsFilter icon
import { Form, Button } from "react-bootstrap";
import CustomInput from "./FooterPages/CustomInput";
import instance from "./common/Api";
import { useNavigate } from "react-router-dom";
import Loader from "./common/Loader";
import { debounce } from "lodash";

const Browse = (props) => {
  const { userData } = props;
  const [containerHeight, setContainerHeight] = useState("auto");
  const [hoveredCard, setHoveredCard] = useState(null);
  // const [browseData, setBrowseData] = useState(null);
  const [browseData, setBrowseData] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedValue, setSelectedValue] = useState("Latest");
  const [filter, setFilter] = useState(false);
  const [filterModel, setFilterModel] = useState(false);
  const [originalBrowseData, setOriginalBrowseData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);

  const navigate = useNavigate();
  function isMobileDevice() {
    return window.matchMedia("(max-width: 1000px)").matches;
  }
  const [isMobile, setIsMobile] = useState(isMobileDevice());

  const [payloadData, setPayloadData] = useState({
    filters: { az: false, latest: false },
    limit: 24,
    offset: 0,
    key: "",
  });

  const updateFilterAz = (value) => {
    // Update the payloadData state with the new filter value
    setPayloadData((prevPayloadData) => ({
      ...prevPayloadData, // Preserve other properties of payloadData
      filters: {
        ...prevPayloadData.filters, // Preserve other filters
        az: value, // Update az filter to true
      },
    }));
  };

  const checkSubscribtion = () => {
    if (userData != null) {
      instance
        .post(`/subscription_plans_web?id=${userData.id}&ip=${props.ip}`)
        .then((response) => {
          //console.log("response.data", response.data);
          const active = response.data.active_plan;
          // console.log(active[0], "<active");
          if (response.data && response.data.active_plan.length > 0) {
            // setActivePlans(response.data.active_plan[0]);
            setIsSubscribed(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          // setLoading(false); // Set loading to false when fetch is complete
        });
      //console.log("User ID:", props.userData.id);
    } else {
      //console.log("User ID not found");
      // setLoading(false);
    }
  };

  const handleClick = (item) => {
    // console.log(userData, "UR");
    console.log(isSubscribed, "UR");

    if (userData != null) {
      if (isSubscribed) {
        // navigate("/play", { state: { someData: item } });
        if (props.mobileDevice) {
          navigate("/play", {
            // state: { src: item.video, poster: item.mobile_image },
            state: { someData: item },
          });
        } else {
          navigate("/media/", { state: { someData: item } });
        }
      } else {
        navigate("/media/", { state: { someData: item } });
      }
    } else {
      // navigate("/login");
      navigate("/media/", { state: { someData: item } });
    }
  };

  const handleClose = () => {
    setFilterModel(false);
  };

  const fetchData = () => {
    instance
      .post(`/browse_web_videos?ip=${props.ip}`, payloadData)
      .then((response) => {
        const newData = response.data.content.filter(
          (item) =>
            !browseData.some((existingItem) => existingItem.id === item.id)
        );

        setHasMoreData(newData.length > 0);

        // Concatenate filtered new data with existing data
        setBrowseData((prevData) => [...prevData, ...newData]);
        setBrowseData((prevData) => {
          const idSet = new Set(prevData.map((item) => item.id)); // Create a Set of existing IDs
          const filteredNewData = newData.filter((item) => !idSet.has(item.id)); // Filter out duplicates
          return [...prevData, ...filteredNewData]; // Concatenate the unique newData with prevData
        });
        setOriginalBrowseData((prevData) => [...prevData, ...newData]);
        // setBrowseData(response.data.content);
        // setOriginalBrowseData(response.data.content);
        // setShowLoadMore(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // setLoading(false);
        // setShowLoadMore(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleScroll = debounce((value) => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.offsetHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (
      (hasMoreData &&
        !loading &&
        windowHeight + scrollTop >= documentHeight - 100) ||
      value === "click"
    ) {
      setLoading(true);
      setPayloadData((prevPayloadData) => ({
        ...prevPayloadData,
        offset: prevPayloadData.offset + 24,
      }));
    }
  }, 300);

  useEffect(() => {
    const fetchDataWithDelay = setTimeout(() => {
      fetchData();
    }, 500);
    // return () =>
    // Initial fetch when component mounts
    // fetchData();

    checkSubscribtion();
    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove scroll event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(fetchDataWithDelay);
    };
  }, [payloadData, props.ip, loading]);

  // useEffect(() => {
  //   const payload = {
  //     filters: { az: false, latest: false },
  //     limit: 20,
  //     offset: 0, // Start with offset 0
  //   };

  //   function fetchData() {
  //     instance
  //       .post(`/browse_web_videos?ip=${props.ip}`, payloadData)
  //       .then((response) => {
  //         setBrowseData(response.data.content); //((prevData) => [...prevData, ...response.data.content]); // Append new data to existing data
  //         setOriginalBrowseData(response.data.content);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching data:", error);
  //       })
  //       .finally(() => {
  //         setLoading(false); // Set loading to false when fetch is complete
  //       });
  //   }

  //   fetchData(); // Initial fetch when component mounts

  //   function handleScroll() {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop !==
  //         document.documentElement.offsetHeight ||
  //       loading // Don't fetch if already loading
  //     ) {
  //       return;
  //     }

  //     // Check if scrolled to the bottom
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop ===
  //       document.documentElement.offsetHeight
  //     ) {
  //       console.error("NEW fetching data:");
  //       setLoading(true); // Set loading to true when fetching new data
  //       payload.offset += 25; // Increment offset by 25 (assuming each fetch fetches 25 items)
  //       fetchData(); // Fetch new data
  //     }
  //   }
  //   function handleScroll() {
  //     const windowHeight = window.innerHeight;
  //     const documentHeight = document.documentElement.offsetHeight;
  //     const scrollTop =
  //       window.pageYOffset || document.documentElement.scrollTop;

  //     if (!loading && windowHeight + scrollTop >= documentHeight) {
  //       // console.log("Reached bottom of page. Fetching more data...");
  //       payload.offset += 25; // Increment offset by 25 (assuming each fetch fetches 25 items)
  //       fetchData(); // Fetch new data
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const handleMouseEnter = (index) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  const sortBrowseDataAlphabetically = () => {
    setLoading(true);
    setBrowseData([]);
    setFilter(true);
    setSelectedValue("Albhabetic");
    // updateFilterAz(true);
    // setBrowseData((prevData) => {
    //   // Remove duplicates before sorting
    //   const uniqueData = [
    //     ...new Map(prevData.map((item) => [item.id, item])).values(),
    //   ];

    //   // Sort the unique data by title
    //   const sortedData = uniqueData.sort((a, b) =>
    //     a.title.localeCompare(b.title)
    //   );

    //   return sortedData;
    // });
    // fetchData();
    setPayloadData((prevPayloadData) => ({
      ...prevPayloadData, // Preserve other properties of payloadData
      filters: {
        ...prevPayloadData.filters, // Preserve other filters
        az: true, // Update az filter to true
      },
      offset: 0,
    }));
    fetchData();
    setFilterModel(false);
  };

  useEffect(() => {
    const handleResize = () => {
      // Calculate the height based on the width to maintain a 16:9 aspect ratio
      const width =
        window.innerWidth > 0 ? window.innerWidth : window.screen.width;
      const height = (width * 9) / 16;
      setContainerHeight(`${height}px`);
    };

    // Call the handleResize function on initial load and whenever the window size changes
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSearchInputChange = (value) => {
    // const newKey = value;

    // setTimeout(() => {
    //   setLoading(true);
    //   setPayloadData((prevPayloadData) => ({
    //     ...prevPayloadData,
    //     key: newKey,
    //     offset: 0,
    //   }));
    //   setBrowseData([]);

    //   fetchData();
    // }, 1000);

    setSearchQuery(value);
  };

  const sortBrowseDataByPublishTime = () => {
    setLoading(true);
    setBrowseData([]);
    setSelectedValue("Latest");
    setFilter(true);
    // setBrowseData((prevData) => {
    //   // Remove duplicates based on publish_time
    //   const uniqueData = prevData.reduce((acc, currentItem) => {
    //     // Check if the currentItem is already in the acc array
    //     const existingItem = acc.find((item) => item.id === currentItem.id);
    //     if (!existingItem) {
    //       acc.push(currentItem);
    //     } else {
    //       // If the currentItem is already in acc, check if its publish_time is greater
    //       // If yes, replace the existing item in acc with the currentItem
    //       if (
    //         new Date(currentItem.publish_time) >
    //         new Date(existingItem.publish_time)
    //       ) {
    //         acc[acc.indexOf(existingItem)] = currentItem;
    //       }
    //     }
    //     return acc;
    //   }, []);

    //   // Sort uniqueData by publish_time in descending order
    //   const sortedData = uniqueData.sort(
    //     (a, b) => new Date(b.publish_time) - new Date(a.publish_time)
    //   );

    //   return sortedData;
    // });
    // setBrowseData((prevData) =>
    //   [...prevData].sort((a, b) => {
    //     const dateA = new Date(a.publish_time);
    //     const dateB = new Date(b.publish_time);
    //     return dateB - dateA; // Sort in descending order (latest to oldest)
    //   })
    // );
    setPayloadData((prevPayloadData) => ({
      ...prevPayloadData, // Preserve other properties of payloadData
      filters: {
        ...prevPayloadData.filters, // Preserve other filters
        latest: true, // Update az filter to true
      },
      offset: 0,
    }));
    fetchData();

    setFilterModel(false);
  };

  const revertToOriginalBrowseData = () => {
    setLoading(true);
    setBrowseData([]);
    setPayloadData((prevPayloadData) => ({
      ...prevPayloadData, // Preserve other properties of payloadData
      filters: {
        ...prevPayloadData.filters, // Preserve other filters
        latest: false,
        az: false, // Update az filter to true
      },
      offset: 0,
      key: "",
    }));
    fetchData();
    setFilter(false);
    setBrowseData(originalBrowseData);
  };
  const handleShowDialog = () => {
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleFilter = (value) => {
    setFilter(true);
    setSelectedValue(value);
    if (value === "Albhabetic") {
      sortBrowseDataAlphabetically();
    } else {
      sortBrowseDataByPublishTime();
    }
    // Do something with the selected value
    // console.log("Selected value:", value);
  };

  return (
    <>
      {loading && (
        <div className="loading-overlay">
          <Loader isLoading={loading} />
          {/* <Spinner animation="border" /> */}
        </div>
      )}
      <div
        style={
          isMobile
            ? {
                width: "100%",
                minHeight: "55rem",
                // position: "relative",
                backgroundImage: `url(./bebuAppBG.JPEG)`,
                backgroundBlendMode: "overlay",
                backgroundSize: "contain",
                // backgroundPosition: "center",
                backgroundColor: "#141414",
                backgroundRepeat: "repeat-y",
              }
            : null
        }
      >
        {/* {loading && (
        <div className="loading-overlay">
          <Spinner animation="border" />
        </div>
      )} */}
        <Modal
          show={filterModel}
          onHide={handleClose}
          style={{ marginTop: "10rem" }}
        >
          <ModalHeader
            style={{
              padding: "0",
              backgroundColor: "black",
              borderBottom: "none",
            }}
          >
            <Button
              variant="secondary"
              style={{
                color: "black",
                backgroundColor: "white",
                width: "30px",
                height: "30px",
                padding: "0px",
                marginLeft: "auto",
                marginRight: "10px",
              }}
              onClick={handleClose}
            >
              x
            </Button>
          </ModalHeader>
          <Modal.Body
            style={{
              backgroundImage: `url(./check-network.png)`,
              backgroundSize: "cover",
              color: "white",
              textAlign: "center",
              paddingTop: "20px",
              paddingBottom: "20px",

              // height: "15rem", // Adjust as needed
              backgroundColor: "black",
            }}
          >
            <h2 style={{ paddingBottom: "1rem" }}>Apply Filter</h2>
            <Button
              variant="secondary"
              style={{
                color: "white",
                backgroundColor: "#58b7aa",
                width: "110px",
              }}
              onClick={() => sortBrowseDataAlphabetically()}
            >
              Albhabetic
            </Button>
            <br />
            <Button
              variant="secondary"
              style={{
                color: "white",
                backgroundColor: "#58b7aa",
                width: "110px",
              }}
              onClick={() => sortBrowseDataByPublishTime()}
            >
              Latest
            </Button>
          </Modal.Body>
        </Modal>
        <Container fluid>
          <Row className="d-flex justify-content-center text-center searchRow mb-2">
            <Col md={10} className="col-md-offset-2">
              <div className="search-wrapper mt-2">
                <Form.Group className="mat-form-field">
                  <div className="position-relative">
                    {/* <Form.Control
                    type="text"
                    placeholder="Search Videos"
                    className="transparent-text-field"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  /> */}
                    <CustomInput
                      placeholder={"Search Video"}
                      // label={"Search Video"}
                      onValueChange={handleSearchInputChange} // Pass the callback function
                    />
                    {filter ? (
                      <Button
                        className="custom-button btn-round ml-2 position-absolute top-0 end-0"
                        style={{
                          fontSize: "14px",
                          fontWeight: "300",
                          width: "auto",
                          margin: "15px 60px",
                          background:
                            "linear-gradient(to bottom, #1fbaaa 0%, #263148 100%, #1fbaaa 100%)",
                        }}
                        onClick={revertToOriginalBrowseData}
                      >
                        {selectedValue}

                        <BsFillXOctagonFill
                          className="position-absolute top-0 end-0"
                          style={{}}
                        />
                      </Button>
                    ) : null}
                    <Button
                      variant="primary"
                      className="custom-button btn-round ml-2 position-absolute top-0 end-0"
                      style={{
                        margin: "15px 10px",
                        background:
                          "linear-gradient(to bottom, #1fbaaa 0%, #263148 100%, #1fbaaa 100%)",
                      }}
                      onClick={() => setFilterModel(true)}
                    >
                      <BsFunnelFill style={{ verticalAlign: "text-top" }} />
                    </Button>
                  </div>
                </Form.Group>
              </div>
            </Col>
          </Row>
        </Container>
        <Container
          style={{ height: containerHeight * 100, paddingBottom: "auto" }}
        >
          <Row className="pb-5">
            {browseData &&
              browseData
                .filter((item) =>
                  item.title.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .map((movie, index) => (
                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={3}
                    key={index}
                    className="image-item"
                  >
                    <img
                      src={
                        isMobile
                          ? movie.browse_image
                          : movie.mobile_image || "./bebu_default_image.png"
                      }
                      onError={(e) => {
                        e.target.src = isMobile
                          ? "./bebu_default_image.png"
                          : "./bebu_default_image_hrz.png";
                      }}
                      alt={`Image ${index + 1}`}
                      className={`image ${
                        hoveredCard === index ? "zoomed-card" : ""
                      }`}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      onClick={() => handleClick(movie)}
                      style={
                        isMobile
                          ? {
                              objectFit: "cover",
                              // width: "8rem",
                              // height: "auto",
                              maxHeight: "100%",
                              aspectRatio: "9 / 16", // Maintain 16:9 aspect ratio
                              borderRadius: "8px",
                            }
                          : {}
                      }
                    />
                    <h2
                      className={`image-title ${
                        hoveredCard === index ? "zoomed-card" : ""
                      }`}
                      onMouseEnter={() => handleMouseEnter(index)} //browse_image
                      onMouseLeave={handleMouseLeave}
                    >
                      {movie.title}
                    </h2>
                  </Col>
                ))}
            {/* {showLoadMore && ( */}
            <div style={{ display: "grid", justifyItems: "center" }}>
              {loading && hasMoreData ? (
                <Button
                  variant="primary"
                  disabled
                  style={{
                    width: "150px",
                    borderColor: "#58b7aa",
                    backgroundColor: "#58b7aa",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: "10px" }}
                  />
                  Loading...
                </Button>
              ) : hasMoreData ? (
                <Button
                  variant="primary"
                  style={{
                    width: "150px",
                    borderColor: "#58b7aa",
                    backgroundColor: "#58b7aa",
                    marginBottom: "30px",
                  }}
                  onClick={() => handleScroll("click")}
                >
                  Load More
                </Button>
              ) : null}
              {/* )} */}
            </div>
            {/* )} */}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Browse;
